import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/dashboard/providers/theme-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/styles/tokens.css");
;
import(/* webpackMode: "eager" */ "/app/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/app/styles/markdown.css");
;
import(/* webpackMode: "eager" */ "/app/styles/highlightjs-light.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"Fira_Code\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-monospace\"}],\"variableName\":\"fontMono\"}");
